<!--
File: UnitsDropdown.vue
Description: show the dropdown combo with the units dropdown list .
-->
<template>
  <md-field>
    <label for="units">{{ label }}</label>
    <md-select v-model='selectedValue' name="units" id="units" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for='(unit, ind) in units' :key='ind' :value='unit.id'>
        {{ unit.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'units-dropdown',

    props: {
      value: { default: null, type: Number },
      label: { default: null, type: String },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null
      }
    },

    mounted() {
      this.$store.dispatch('LOAD_UNIT_LIST', !this.is_required).then(() => {
        if (this.value && !this.selectedValue) this.selectedValue = this.value;
      })
    },

    methods: {
      onChange() {
//        const desc = !this.selectedValue ? '' : this.units.find((el) => el.id === this.selectedValue).name
        const desc = this.units.find((el) => el.id === this.selectedValue)?.name
        this.$emit('input', this.selectedValue, desc)
      },
    },

    computed: {
      units() {
        return this.$store.state.Units.list;
      }
    },

    watch: {
      value(newValue) { // Следим за изменениями value (v-model)
        if (newValue !== this.selectedValue) {
          this.selectedValue = newValue;
        }
      },
    }
  }
</script>